import { css } from '@emotion/react';
import { alignCss } from '~/styles/alignCss';

export const errorMessage = {
  container: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 4px;
  `,
  icon: css`
    padding-top: 2px;
    flex-shrink: 0;
  `,
  text: css`
    white-space: pre-wrap;
    ${alignCss({ pt: 0.5 })}
  `,
};
