import { css } from '@emotion/react';
import { componentTokens } from '~/styles/designToken';
import { layout } from '~/styles/layout';
import { textCss } from '~/styles/textCss';

const formHeightPx = '44px';

export const container = (options: { width?: number }) => css`
  position: relative;
  ${options.width ? `width: ${options.width}px` : '100%'};
`;

export const eyeContainer = {
  container: css`
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 12px;
    top: 0;
    right: 0;
    height: ${formHeightPx};
  `,
  eye: css`
    width: 20px;
  `,
};

export const input = (options: { readOnly?: boolean; error: boolean; number: boolean }) => css`
  ${textCss({ size: 'm', weight: 'regular' })}

  width: 100%;
  box-sizing: border-box;
  height: ${formHeightPx};
  line-height: ${formHeightPx};
  border: 2px solid ${componentTokens.border.input.default};
  border-radius: 8px;
  padding: 0 ${layout.baseSize.margin}px;
  border-color: ${options.error
    ? componentTokens.border.input.error
    : componentTokens.border.input.default};
  color: ${options.readOnly
    ? componentTokens.textIconSet.disabled
    : componentTokens.textIconSet.default};
  cursor: ${options.readOnly ? 'not-allowed' : 'default'};

  &:focus {
    box-shadow: none;
  }

  ${options.readOnly
    ? `
      background-color: ${componentTokens.background.input.disabled};
      border-color: ${componentTokens.border.input.disabled}
      `
    : `
      &:hover {
        border-color: ${componentTokens.border.input.hover};
      }
      &:focus {
        border-color: ${componentTokens.border.input.selected}; 
      }
    `}

  ${options.error
    ? `
      background-color: ${componentTokens.background.input.error};
      `
    : ''}

  ${options.number && 'text-align: right;'}

  &::placeholder {
    color: ${componentTokens.text.placeholder};
  }
`;

export const description = css`
  ${textCss({ size: 'xs', weight: 'regular' })}
`;

export const unit = css`
  position: absolute;
  text-align: right;
  right: 8px;
  line-height: ${formHeightPx};
  color: ${componentTokens.textIconSet.default};
`;

export const calendar = css`
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    pointer-events: none;
  }
`;
