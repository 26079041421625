import { css } from '@emotion/react';
import { alignCss } from '~/styles/alignCss';
import { componentTokens } from '~/styles/designToken';
import { layout } from '~/styles/layout';
import { textCss } from '~/styles/textCss';

export const container = css`
  & + & {
    margin-top: ${layout.baseSize.margin * 2}px;
  }
`;

export const wrapper = css`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4px;
`;

export const labelPrimary = css`
  display: flex;
`;

export const label = css`
  ${textCss({ size: 'm', weight: 'bold' })}

  line-height: 24px;
`;

export const labelDesc = (opt: { type: 'alert' | 'note' | 'notice' }) => css`
  ${textCss({ size: 'xs', weight: 'bold' })};

  height: 16px;
  line-height: 16px;
  color: ${componentTokens.textIconSet.inverse};
  background-color: ${opt.type === 'alert'
    ? componentTokens.background.labelDesc.primary
    : opt.type === 'notice'
      ? componentTokens.background.labelDesc.secondary
      : componentTokens.background.labelDesc.tertiary};
  padding: 2px 4px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
`;

export const tooltip = css`
  ${alignCss({ ml: 1 })}
`;

export const explanation = css`
  ${textCss({ size: 'xs', weight: 'regular' })}
`;

export const childContainer = css`
  margin-top: ${layout.baseSize.margin}px;
  position: relative;
`;

export const reactTooltip = css`
  .__react_component_tooltip {
    pointer-events: auto !important;
  }
`;
