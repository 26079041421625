import React from 'react';
import { Global } from '@emotion/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as styles from './index.styles';

export type Place = 'top' | 'bottom' | 'left' | 'right';

export type Props = React.PropsWithChildren<{
  tooltipId: string;
  place?: Place;
  className?: string;
}>;

// 表現の一貫性を保つためにサードパーティの ReactTooltip をラップしたコンポーネント
// USAGE:
//   - ホバーでツールチップを表示させたい要素には必ず data-tooltip-id 属性を付与する
//     - e.g) <span data-tooltip-id="sample">
//   - ツールチップの内容を children として定義する
//   - tooltipId は呼び出し元の内容に応じて適切なものを定義する
//     - ReactTooltip は内部的に呼び出し元の data 属性と ReactTooltip の id属性 を紐付けている
//   - ツールチップ幅の変更等は className を用いる
// NOTE:
//   - ？アイコンと組み合わせて使用したい場合は HintTooltip コンポーネントを使用する
//   - ReactTooltip のv5 系からAPIが変わるので、ドキュメント参照時は注意
export const Tooltip: React.FC<Props> = ({ tooltipId, place, children, className }: Props) => (
  <>
    <ReactTooltip
      id={tooltipId}
      place={place}
      className={`${styles.tooltipClassName} ${className}`}
    >
      {children}
    </ReactTooltip>
    <Global styles={styles.customTooltip} />
  </>
);
