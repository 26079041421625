import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { zIndex } from '~/styles/zIndex';

export const tooltipClassName = 'custom-tooltip';

export const icon = css`
  cursor: pointer;
  display: inline;
  vertical-align: middle;
  width: 24px;
  height: 24px;
`;

export const customTooltip = css`
  .${tooltipClassName} {
    ${textCss({ size: 'xs', weight: 'regular' })}

    line-height: 20px;
    z-index: ${zIndex.tooltip};
  }
`;
